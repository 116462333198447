import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/',
		alias: '/page/app',
		component: () => import('./views/app'),
		redirect: '/page/app/dashboard/map',
		//beforeEnter: AuthRequired,
		children: [
			{
				path: 'page/app/dashboard',
				component: () => import('./views/app/dashboard'),
				redirect: '/page/app/dashboard/map',
				children: [
					{
						name: 'dashboardMap',
						path: '/page/app/dashboard/map',
						component: () => import(/* webpackChunkName: "admin" */ './views/app/dashboard/map')
					},
					{
						name: 'dashboardTable',
						path: '/page/app/dashboard/table',
						component: () => import(/* webpackChunkName: "admin" */ './views/app/dashboard/table')
					}
				]
			},
			{
				name: 'manager',
				path: '/page/app/manager/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/manager')
			},
			{
				name: 'users',
				path: '/page/app/users/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/users')
			},
			{
				name: 'sources',
				path: '/page/app/sources/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/sources')
			},
			{
				name: 'scenarios',
				path: '/page/app/scenarios/:action?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/scenarios'),
			},
			{
				name: 'scenarioOverview',
				path: '/page/app/scenario/overview/:id',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/scenarios/overview')
			},
			{
				name: 'administrator',
				path: '/page/app/administrator',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/administrator')
			},
			{
				name: 'translations',
				path: '/page/app/translations',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/translations'),
			},
			{
				name: 'helpbutton',
				path: '/page/app/helpbutton',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/helpbutton'),
			},
			{
				name: 'calculations',
				path: '/page/app/calculations',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/calculations'),
			},
			{
				name: 'ScenarioCalculations',
				path: '/page/app/calculations/overview/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/calculations/overview')
			}
		]
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ path: 'login', component: () => import('./views/public/user/Login')  },
			{ path: 'register', component: () => import('./views/public/user/Register') },
			{ path: 'forgot-password', component: () => import('./views/public/user/ForgotPassword') },
			{ path: 'register/:token', component: () => import('./views/public/user-register') }
		],

	},
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
